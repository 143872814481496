<template>
    <v-data-table :headers="headers" :items="requestedShows" :items-per-page="dataTableDimensions.itemsPerPage">
        <template v-slot:item.name="{ item }">
            {{ item.data().name }}
        </template>
        <template v-slot:item.location="{ item }">
            {{ item.data().location }}
        </template>
        <template v-slot:item.organizer="{ item }">
            {{ item.data().organizer }}
        </template>
        <template v-slot:item.startDate="{ item }">
            {{ item.data().startDate }}
        </template>
        <template v-slot:item.endDate="{ item }">
            {{ item.data().endDate }}
        </template>
        <template v-slot:item.noOfKiosks="{ item }">
            {{ item.data().noOfKiosks }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn color="primary" @click="approveItem(item)"> Approve </v-btn>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'requestedShows',
    data() {
        return {
            adminHeaders: [
                { text: 'Show Name', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Organizer', value: 'organizer' },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'No of Kiosks', value: 'noOfKiosks' },
            ],
            superAdminHeaders: [
                { text: 'Show Name', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Organizer', value: 'organizer' },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'No of Kiosks', value: 'noOfKiosks' },
                { text: 'Actions', value: 'actions', sortable: false }
            ]
        }
    },
    computed: {
        ...mapGetters(['shows', 'userProfile', 'dataTableDimensions']),
        requestedShows() {
            return this.shows.filter(show => show.data().status === 'REQUESTED')
        },
        headers() {
            if (this.userProfile.data().userRole.toLowerCase() === 'super admin') {
                console.log('Super Admin')
                return this.superAdminHeaders
            } else {
                return this.adminHeaders
            }
        }
    },
    methods: {
        approveItem(show) {
            console.log('Item: ', show.data())
            this.$store.dispatch('approveShow', show)
        }
    }
}
</script>