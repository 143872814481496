<template>
    <v-dialog v-model="show" persistent width="25%">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="primary">
                <v-icon left> mdi-plus </v-icon>
                Request New Show
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="justify-center"> Request Show </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form>
                    <v-text-field label="Show Name" v-model="showDetails.name"></v-text-field>
                    <v-text-field label="Location" v-model="showDetails.location"></v-text-field>
                    <v-text-field label="Organizer" v-model="showDetails.organizer"></v-text-field>
                    <v-row>
                        <v-col cols="6">
                            <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition"
                                offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="showDetails.startDate" label="Start Date" prepend-icon="mdi-calendar"
                                        v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="showDetails.startDate" scrollable> </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition"
                                offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="showDetails.endDate" label="End Date" prepend-icon="mdi-calendar"
                                        v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="showDetails.endDate" scrollable> </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-text-field label="No of Kiosks" v-model="showDetails.noOfKiosks"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn width="125" outlined color="error" @click="show = !show"> Cancel </v-btn>
                <v-btn width="125" color="primary" @click="requestShow()"> Request </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'newShow',
    data() {
        return {
            show: false,
            showDetails: {
                name: '',
                location: '',
                organizer: '',
                startDate: '',
                endDate: '',
                noOfKiosks: '',
                status: 'REQUESTED',
                clientName: ''
            }
        }
    },
    computed:{
        ...mapGetters(['userProfile'])
    },
    methods:{
        requestShow(){
            this.showDetails.clientName = this.userProfile.data().clientName
            this.$store.dispatch('requestShow', this.showDetails).then(() => {
                this.show = false
            })
            console.log('Show: ', this.showDetails)
        }
    }
}
</script>
