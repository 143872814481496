var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.requestedShows,"items-per-page":_vm.dataTableDimensions.itemsPerPage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().name)+" ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().location)+" ")]}},{key:"item.organizer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().organizer)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().startDate)+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().endDate)+" ")]}},{key:"item.noOfKiosks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().noOfKiosks)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.approveItem(item)}}},[_vm._v(" Approve ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }