<template>
    <v-data-table :headers="headers" :items="completedShows" :items-per-page="dataTableDimensions.itemsPerPage">
        <template v-slot:item.name="{ item }">
            {{ item.data().name}}
        </template>
        <template v-slot:item.location="{ item }">
            {{ item.data().location}}
        </template>
        <template v-slot:item.organizer="{ item }">
            {{ item.data().organizer}}
        </template>
        <template v-slot:item.startDate="{ item }">
            {{ item.data().startDate}}
        </template>
        <template v-slot:item.endDate="{ item }">
            {{ item.data().endDate}}
        </template>
        <template v-slot:item.noOfKiosks="{ item }">
            {{ item.data().noOfKiosks}}
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'requestedShows',
    data() {
        return {
            adminHeaders: [
                { text: 'Show Name', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Organizer', value: 'organizer' },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'No of Kiosks', value: 'noOfKiosk' }
            ],
            superAdminHeaders: [
                { text: 'Show Name', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Organizer', value: 'organizer' },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'No of Kiosks', value: 'noOfKiosks' },
                { text: 'Actions', value: 'action'}
            ]
        }
    },
    computed:{
        ...mapGetters(['shows', 'userProfile', 'dataTableDimensions']),
        completedShows(){
            return this.shows.filter(show => show.data().status === 'DONE')
        },
        headers(){
            if(this.userProfile.data().userRole === 'Super Admin'){
                return this.superAdminHeaders
            }else{
                return this.adminHeaders
            }
        }
    }
}
</script>