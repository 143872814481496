<template>
    <v-data-table :headers="headers" :items="upcomingShows" :items-per-page="dataTableDimensions.itemsPerPage">
        <template v-slot:item.name="{ item }">
            {{ item.data().name }}
        </template>
        <template v-slot:item.location="{ item }">
            {{ item.data().location }}
        </template>
        <template v-slot:item.organizer="{ item }">
            {{ item.data().organizer }}
        </template>
        <template v-slot:item.startDate="{ item }">
            {{ item.data().startDate }}
        </template>
        <template v-slot:item.endDate="{ item }">
            {{ item.data().endDate }}
        </template>
        <template v-slot:item.noOfKiosks="{ item }">
            {{ item.data().noOfKiosks }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon @click="viewShowDetails(item)" color="primary" small>mdi-eye</v-icon>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'upcomingShows',
    data() {
        return {
            adminHeaders: [
                { text: 'Show Name', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Organizer', value: 'organizer' },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'No of Kiosks', value: 'noOfKiosks' },
                { text: 'Actions', value: 'actions' }
            ],
            superAdminHeaders: [
                { text: 'Show Name', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Organizer', value: 'organizer' },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'No of Kiosks', value: 'noOfKiosks' },
                { text: 'Actions', value: 'actions' }
            ]
        }
    },
    computed: {
        ...mapGetters(['shows', 'userProfile', 'dataTableDimensions']),
        upcomingShows() {
            return this.shows.filter(show => show.data().status === 'FUTURE')
        },
        headers() {
            if (this.userProfile.data().userRole === 'super admin') {
                return this.superAdminHeaders
            } else {
                return this.adminHeaders
            }
        }
    },
    methods: {
        viewShowDetails(showDetails) {
            console.log('Upcoming Show Details: ', showDetails.data())
            //this.$store.commit('clearKioskLicenses')
            this.$store.commit('setSelectedShow', showDetails)
            this.$router.push('/showInfo')
        }
    }
}
</script>