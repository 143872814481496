var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Request New Show ")],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Request Show ")]),_c('v-divider'),_c('v-card-text',[_c('v-form',[_c('v-text-field',{attrs:{"label":"Show Name"},model:{value:(_vm.showDetails.name),callback:function ($$v) {_vm.$set(_vm.showDetails, "name", $$v)},expression:"showDetails.name"}}),_c('v-text-field',{attrs:{"label":"Location"},model:{value:(_vm.showDetails.location),callback:function ($$v) {_vm.$set(_vm.showDetails, "location", $$v)},expression:"showDetails.location"}}),_c('v-text-field',{attrs:{"label":"Organizer"},model:{value:(_vm.showDetails.organizer),callback:function ($$v) {_vm.$set(_vm.showDetails, "organizer", $$v)},expression:"showDetails.organizer"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar"},model:{value:(_vm.showDetails.startDate),callback:function ($$v) {_vm.$set(_vm.showDetails, "startDate", $$v)},expression:"showDetails.startDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.showDetails.startDate),callback:function ($$v) {_vm.$set(_vm.showDetails, "startDate", $$v)},expression:"showDetails.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar"},model:{value:(_vm.showDetails.endDate),callback:function ($$v) {_vm.$set(_vm.showDetails, "endDate", $$v)},expression:"showDetails.endDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.showDetails.endDate),callback:function ($$v) {_vm.$set(_vm.showDetails, "endDate", $$v)},expression:"showDetails.endDate"}})],1)],1)],1),_c('v-text-field',{attrs:{"label":"No of Kiosks"},model:{value:(_vm.showDetails.noOfKiosks),callback:function ($$v) {_vm.$set(_vm.showDetails, "noOfKiosks", $$v)},expression:"showDetails.noOfKiosks"}})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"width":"125","outlined":"","color":"error"},on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"width":"125","color":"primary"},on:{"click":function($event){return _vm.requestShow()}}},[_vm._v(" Request ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }